export default function getFriendlyEnvName(environment, environmentList) {
  if (environment) {
    let selected = environmentList.filter((row) => {
      return row.name == environment;
    });
    return selected[0].friendlyName;
  } else {
    return null;
  }
}
